import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { FetchSchedule } from '../FetchSchedule';

export const PagerdutyBodyContent = () => {
  return (
    <>
      <Typography
        variant="h3"
        style={{ marginBottom: '10px', textAlign: 'center' }}
      >
        Out Of Hours SRE on-call engineers.
      </Typography>

      <Typography style={{ marginBottom: '30px', textAlign: 'center' }}>
        SRE team is available out of hours for Critical Issues only. To notify
        the SRE engineer of a critical incident, please call our number:{' '}
        <b>
          <a href="tel:+441174727164">+44 1174 727164</a>
        </b>{' '}
        (as we wont be checking Slack, email or Jira)
      </Typography>

      <br />

      <Grid container spacing={4} justifyContent="center">
        <Grid xs={12} md={4} xl={3}>
          <FetchSchedule scheduleId="PIDBYFG" />
        </Grid>

        <Grid xs={12} md={4} xl={3}>
          <FetchSchedule scheduleId="P2F8T5K" />
        </Grid>
      </Grid>

      <br />
      <br />

      <Typography
        variant="h3"
        style={{ marginBottom: '10px', marginTop: '30px', textAlign: 'center' }}
      >
        Replay Out Of Hours on-call engineer.
      </Typography>

      <Typography style={{ textAlign: 'center' }}>
        DO NOT CALL THE ABOVE NUMBER.
      </Typography>

      <Typography style={{ marginBottom: '10px', textAlign: 'center' }}>
        That's only for Legend websites, the SRE team is not supporting Replay
        yet. Replay follow a different process.
      </Typography>

      <br />

      <Grid container spacing={4} justifyContent="center">
        <Grid xs={12} md={4} xl={3}>
          <FetchSchedule scheduleId="PROH18J" />
        </Grid>
      </Grid>
    </>
  );
};
