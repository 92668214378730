import React from 'react';
import { Portal } from '@mui/base';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';

const ADDON_FEEDBACK_CONTAINER_ID = 'techdocs-styler';
const ADDON_FEEDBACK_CONTAINER_SELECTOR = `#${ADDON_FEEDBACK_CONTAINER_ID}`;
const PAGE_MAIN_CONTENT_SELECTOR = '[data-md-component="main"] .md-content';

const css = `
  .md-nav--primary .md-nav__title, .md-nav--secondary .md-nav__title {
    font-size: 1.3rem;
    padding-bottom: 10px;
    background: none;
    border: none;
    box-shadow: none;
  }

  @media (min-width: 1600px) {
    .md-content {
      max-width: 800px
    }
  }

  .md-content h1 {
    margin-bottom: 0;
  }

  .md-content hr {
    border-bottom: .05rem solid var(--md-default-fg-color--lightest);
  }
`;

/**
 * Show report issue button when text is highlighted
 */
export const TechDocsStylerAddon = () => {
  const [mainContent] = useShadowRootElements([PAGE_MAIN_CONTENT_SELECTOR]);

  let [feedbackContainer] = useShadowRootElements([
    ADDON_FEEDBACK_CONTAINER_SELECTOR,
  ]);

  if (!feedbackContainer) {
    feedbackContainer = document.createElement('div');
    feedbackContainer.setAttribute('id', ADDON_FEEDBACK_CONTAINER_ID);
    mainContent!.prepend(feedbackContainer);
  }

  return (
    <Portal container={feedbackContainer}>
      <style>{css}</style>
    </Portal>
  );
};
