import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';

import { createPlugin } from '@backstage/core-plugin-api';
import { TechDocsStylerAddon } from './components/TechdocsStyler/TechdocsStyler';

export const techdocsStylerPlugin = createPlugin({
  id: 'techdocs-styler',
});

export const Styler = techdocsStylerPlugin.provide(
  createTechDocsAddonExtension({
    name: 'TechDocsStyler',
    location: TechDocsAddonLocations.Content,
    component: TechDocsStylerAddon,
  }),
);
