import useAsync from 'react-use/lib/useAsync';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { ForwardedError } from '@backstage/errors';
import { User } from '../types';

export const useFetchUsers = (scheduleId: string) => {
  const { fetch } = useApi(fetchApiRef);
  const config = useApi(configApiRef);

  const getUtcDates = () => {
    const now = new Date();
    const formatDate = (date: Date) => {
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(
          2,
          '0',
        )}-${date.getUTCDate().toString().padStart(2, '0')}T${date
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${date
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}:00`;
    };

    const currentUtc = formatDate(now);
    const futureUtc = new Date(now.getTime() + 10 * 60000); // Add 10 minutes
    const utcPlus10Minutes = formatDate(futureUtc);

    return `since=${currentUtc}&until=${utcPlus10Minutes}`;
  };

  const { value, loading, error } = useAsync(async () => {
    try {
      const backendUrl = config.getString('backend.baseUrl');
      const url = `${backendUrl}/api/proxy/pagerduty/schedules/${scheduleId}/users?${getUtcDates()}`;

      const response = await fetch(url);
      const data = await response.json();

      return (data?.users as User[]) || [];
    } catch (err) {
      throw new ForwardedError('Error fetching PagerDuty data:', err);
    }
  }, [scheduleId, config]);

  return { value, loading, error };
};
