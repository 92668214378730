/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';

import CategoryIcon from '@mui/icons-material/Category';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import ExtensionIcon from '@mui/icons-material/Extension';
import GitHubIcon from '@mui/icons-material/GitHub';
import HomeIcon from '@mui/icons-material/Home';
import ConstructionIcon from '@mui/icons-material/Construction';
import LayersIcon from '@mui/icons-material/Layers';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import MapIcon from '@mui/icons-material/MyLocation';
import BookIcon from '@mui/icons-material/Book';
import MenuIcon from '@mui/icons-material/Menu';
import MoneyIcon from '@mui/icons-material/MonetizationOn';
import People from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloudIcon from '@mui/icons-material/Cloud';
import { HelmIcon } from '@internal/backstage-plugin-helm-dashboard';
import { CloudcraftIcon } from '@internal/backstage-plugin-cloudcraft-infra';

import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarSubmenu,
  SidebarSubmenuItem,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
} from '@backstage/core-components';
import SidebarLogo from '../logos/SidebarLogo';
import {
  IconComponent,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import styled from '@mui/styles/styled';
import Clarity from '@microsoft/clarity';

const SidebarDivider = styled('hr')(({ theme }) => ({
  height: 1,
  width: '100%',
  background: '#6C38FF',
  border: 'none',
  margin: theme.spacing(1.2, 0),
}));

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const config = useApi(configApiRef);
  const projectId = config.getString('clarity.projectId');
  Clarity.init(projectId);

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />

        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>

        <SidebarDivider />

        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem
            className="home-sidebar"
            icon={HomeIcon as IconComponent}
            to="/"
            text="Home"
          />

          <MyGroupsSidebarItem
            singularTitle="My Group"
            pluralTitle="My Groups"
            icon={People as IconComponent}
          />

          <SidebarItem
            className="explore-sidebar"
            icon={LayersIcon as IconComponent}
            to="explore"
            text="Explore"
          />

          <SidebarDivider />

          <SidebarItem
            className="catalog-sidebar"
            icon={CategoryIcon as IconComponent}
            to="/catalog"
            text="Catalog"
          />

          {window.location.hostname !== 'backstage.net.management' && (
            <SidebarItem
              className="catalog-import-sidebar"
              icon={UploadFileIcon as IconComponent}
              to="/catalog-import"
              text="Catalog Import"
            />
          )}

          <SidebarItem
            className="api-docs-sidebar"
            icon={ExtensionIcon as IconComponent}
            to="api-docs"
            text="APIs"
          />

          <SidebarItem
            className="infra-sidebar"
            icon={CloudIcon as IconComponent}
            text="Infrastructure"
          >
            <SidebarSubmenu title="Infrastructure">
              <SidebarSubmenuItem
                icon={HelmIcon}
                to="/helm/dashboard"
                title="Helm Releases"
              />

              <SidebarSubmenuItem
                icon={CloudcraftIcon}
                to="/cloudcraft-infra"
                title="Cloudcraft"
              />
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarItem
            className="github-sidebar"
            icon={GitHubIcon as IconComponent}
            to="/github-onboarding"
            text="GitHub repos"
          />

          <SidebarItem
            className="costinsights-sidebar"
            icon={MoneyIcon as IconComponent}
            to="cost-insights"
            text="Cost Insights"
          />

          <SidebarDivider />

          <SidebarItem
            className="playbooks-sidebar"
            icon={BookIcon as IconComponent}
            text="Playbooks"
          >
            <SidebarSubmenu title="Playbooks">
              <SidebarSubmenuItem title="Central" to="/playbooks/central" />
              <SidebarSubmenuItem
                title="Gaming Super Pillar"
                to="/playbooks/gaming-super-pillar"
              />
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarItem
            className="docs-sidebar"
            icon={LibraryBooks as IconComponent}
            to="docs"
            text="Tech Docs"
          />

          <SidebarItem
            className="tech-radars-sidebar"
            icon={MapIcon as IconComponent}
            text="Tech Radars"
          >
            <SidebarSubmenu title="Tech Radars">
              <SidebarSubmenuItem title="SRE Tech Radar" to="sre-tech-radar" />
              <SidebarSubmenuItem
                title="CORG Tech Radar"
                to="corg-tech-radar"
              />
              <SidebarSubmenuItem
                title="Data Tech Radar"
                to="data-tech-radar"
              />
              <SidebarSubmenuItem
                title="Gaming Tech Radar"
                to="gaming-tech-radar"
              />
              <SidebarSubmenuItem
                title="Sports Tech Radar"
                to="sports-tech-radar"
              />
              <SidebarSubmenuItem
                title="Phoenix Tech Radar"
                to="phoenix-tech-radar"
              />
              <SidebarSubmenuItem
                title="Poker - Replay Tech Radar"
                to="poker-tech-radar"
              />
              <SidebarSubmenuItem
                title="CardsChat Tech Radar"
                to="cardschat-tech-radar"
              />
              <SidebarSubmenuItem
                title="MoneyWise Tech Radar"
                to="moneywise-tech-radar"
              />
              <SidebarSubmenuItem
                title="Covers Tech Radar"
                to="covers-tech-radar"
              />
            </SidebarSubmenu>
          </SidebarItem>

          <SidebarDivider />

          <SidebarItem
            className="create-sidebar"
            icon={CreateComponentIcon as IconComponent}
            to="create"
            text="Create..."
          />
        </SidebarGroup>

        <SidebarSpace />

        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarDivider />

          <SidebarItem icon={ConstructionIcon} to="devtools" text="DevTools" />

          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
